@import '~app/mixins';

.campaignEditor {
  width: 100%;
}

.header,
.left,
.right {
  display: flex;
  align-items: center;
}

.header {
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 30px 20px 40px;
}

.title {
  color: $color-blue-dark;
  font-size: 24px;
  line-height: 32px;
}

.btn {
  margin: 0 10px;
  min-width: 140px;

  &:last-child {
    margin-right: 0;
  }
}

.body {
  padding: 0 20px 35px;
  height: calc(100vh - 105px);
  overflow-x: hidden;
  overflow-y: auto;
}

.row {
  $offset: 20px;

  display: flex;
  margin-left: -$offset;
  margin-right: -$offset;

  .cell25,
  .cell50 {
    padding-left: $offset;
    padding-right: $offset;
  }

  .cell25 {
    width: 25%;
  }

  .cell50 {
    width: 50%;
  }
}

.centered {
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.bottomed {
  height: 100%;
  align-self: flex-end;
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.label {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 20px;
}

.formControlLabel {
  display: block;
}

.recipientListLabel {
  display: flex;
  justify-content: space-between;
}

.btnEditRemove {
  margin: 0 10px;
}
